.logoWrapper {
  height: 48px;
  margin: 16px;
  background: #fff;
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;

  .logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.layout {
  height: 100%;
}

.triggerButton {
  font-size: 16px;
  width: 64px !important;
  height: 64px;
}

.content {
  position: relative;
  margin: 24px 16px;
  padding: 24px;
  min-height: 280px;
  height: 100%;
  overflow-y: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 0;
}
