.loginFormWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.loginFormContainer {
  border-radius: 10px;
  padding: 20px;
  max-width: 400px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e7e7e7;
}

.forgotPwdButton {
  margin: 15px 0 0;
  width: 100%;
}

.loginIcon {
  margin: 0 auto 20px;
  max-width: 60px;
  width: 100%;
  display: block;
}

.socialNetworks {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 19px 0 0;
}

.socialButton {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 6px;
  margin: 0 10px 0 0;
  background: #fff;
  border: 1px solid #dadce0;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    border-color: #d2e3fc;
    background: rgba(66, 133, 244, 0.04);
  }

  & img {
    width: 100%;
  }

  &:last-child {
    margin: 0;
  }
}
