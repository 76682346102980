.firmwaresWrapper {
  grid-gap: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.firmwaresContainer {
  display: flex;
  flex-direction: column;
}

.firmwaresControls {
  display: flex;
  margin: 0 0 15px;
  width: 100%;
  justify-content: flex-end;
}

.firmwareCard {
  display: flex;
  flex-direction: column;
  width: 100%;
}
