body {
  background: #ffffff;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

.ant-upload-list-item-image {
  object-fit: cover;
}
