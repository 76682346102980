.notFoundContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  .ant-result-subtitle {
    color: #fff;
  }
}
