.vkLoginButton {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 6px;
  margin: 0 10px 0 0;
  background: #fff;
  border: 1px solid #dadce0;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    border-color: #d2e3fc;
    background: rgba(66, 133, 244, 0.04);
  }

  & img {
    width: 100%;
  }

  &:last-child {
    margin: 0;
  }
}
